import {
    ChakraProvider,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
    Input,
    FormLabel,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
    extendTheme,
  Stack,
} from "@chakra-ui/react";

import { useEffect, useState, useRef } from 'react';

import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";


import "utils/style.css";

import cashFlowApi from "utils/cashFlowApi.js";
import paymentApi from "utils/paymentApi.js";
import intervals from 'utils/intervals.json';
import vatsFromFile from 'utils/vats.json';
import { StartDate } from "./StartDate";
import { Category } from "./Category";
import { Periodicity } from "./Periodicity";
import { Vat } from "./Vat";
import { VariableAmount } from "./VariableAmount";
import {ExcludeForecast } from "./ExcludeForecast";
import {MonthValue} from "./MonthValue";

import {
  useDisclosure,
  Modal,
  Switch,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';


function EntryModal(props) {
 
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [name, setName] = useState([]);
  const [id, setId] = useState(props.params.data.id);
  //setId(props.params.data.id);
  const [mappedName, setMappedName] = useState([]);

  const [fixedAmount, setFixedAmount] = useState();
   const fixedAmountChange = (event) => {
    console.log(Number(event.target.value));
     console.log("montant :  " + event.target.value);
     setFixedAmount(event.target.value);
   };

  const [months, setMonths] = useState([]);
  const [monthPeriods, setMonthPeriods] = useState([]);

  const [periodicitySelected, setPeriodicitySelected] = useState();

  let history = useHistory();
  let monthToShow = [];
  let monthstoPeriod = [];

  const [januaryAmount, setJanuaryAmount] = useState("");
  const januaryOnChange = (value) => {
    console.log("montant :  " + value);
    setJanuaryAmount(value);
  };

  const [februaryAmount, setFebruaryAmount] = useState("");
  const februaryOnChange = (value) => {
    console.log("montant :  " + value);
    setFebruaryAmount(value);
  };

  const [marchAmount, setMarchAmount] = useState("");
  const marchOnChange = (value) => {
    console.log("montant :  " + value);
    setMarchAmount(value);
  };

  const [aprilAmount, setAprilAmount] = useState("");
  const aprilOnChange = (value) => {
    console.log("montant :  " + value);
    setAprilAmount(value);
  };

  const [mayAmount, setMayAmount] = useState("");
  const mayOnChange = (value) => {
    console.log("montant :  " + value);
    setMayAmount(value);
  };

  const [juneAmount, setJuneAmount] = useState("");
  const juneOnChange = (value) => {
    console.log("montant :  " + value);
    setJuneAmount(value);
  };

  const [julyAmount, setJulyAmount] = useState("");
  const julyOnChange = (value) => {
    console.log("montant :  " + value);
    setJulyAmount(value);
  };

  const [augustAmount, setAugustAmount] = useState("");
  const augustOnChange = (value) => {
    console.log("montant :  " + value);
    setAugustAmount(value);
  };

  const [septemberAmount, setSeptemberAmount] = useState("");
  const septemberOnChange = (value) => {
    console.log("montant :  " + value);
    setSeptemberAmount(value);
  };

  const [octoberAmount, setOctoberAmount] = useState("");
  const octoberOnChange = (value) => {
    console.log("montant :  " + value);
    setOctoberAmount(value);
  };

  const [novemberAmount, setNovemberAmount] = useState("");
  const novemberOnChange = (value) => {
    console.log("montant :  " + value);
    setNovemberAmount(value);
  };

  const [decemberAmount, setDecemberAmount] = useState("");
  const decemberOnChange = (value) => {
    console.log("montant :  " + value);
    setDecemberAmount(value);
  };

  const [isActive, setIsActive] = useState();
  let [defaultVat, setdefaultVat] = useState();

  useEffect(() => {
    if (props.isOpen) {
      resetMonths();
console.log(props.categoryType);
    setId(props.params.data.id);
    setName(props.params.data.poste);
    setPeriodicity(props.recurringInterval.value);
    setPeriodicityLabel(props.recurringInterval.label);

    setCategory(props.categoryId);
    setCategoryId(props.categoryId);    
    setCategoryName(props.categoryName);
    console.log(props.selectedCategory);
    setPeriodicitySelected(props.recurringInterval);
    let vat=vats[0];
    if(props.vatSelected!=null){
      vat=props.vatSelected["value"];
    }
 
    setDisabled(props.disabled);
    setVatSelected(vat);
    setMappedName(props.params.data.num);
    setFixedAmount(props.fixedAmount);

   // setStartDate(props.startDate);
    setStartDateFormated(props.startDateFormated);

   // setFinishDate(props.finishDate);
    setFinishDateFormated(props.finishDateFormated);
    console.log(":"+id);
    if (id=="" || id === undefined)    setShowAdvancedMode(true);
    //console.log("ResetMonths");
    console.log(props.params.data);
    console.log(props.monthsColums);
    // Object.entries(props.params.data.periods).forEach(([key, id]) => {
    //   console.log(key + " :" + id);
    //   let month = key.substr(4, 2);
    //   monthstoPeriod.push("january:" + id);
    // });


        if (props.params.data.periods !==undefined && props.params.data.periods !==null){
           setShowVariableAmount(true);
           setShowFixedAmount(false);

        }
        else {
          setShowVariableAmount(false);
          setShowFixedAmount(true);
        }
  //      console.log("vats");
    if (vats.length == 0) {    } 
//console.log(vats);
let newVats=[];
      Object.entries(vatsFromFile["hydra:member"]).forEach(([key, value]) => {

        let checked= localStorage.getItem(value["@id"]);
        //console.log(checked);
        if(checked===null)checked=false;
        if(checked==='true')newVats.push({ value: value["@id"], label: value.rate });
      });
      if(newVats.length<1)    newVats.push({ value: '/api/vats/1', label: '20.00',category:'Métropole',country:'France',checked:'true'});
 
    setVats(newVats);
    }

  }, [props.isOpen]);

  //setIsActive(true);

  function resetMonths() {
    setJanuaryAmount();
    setFebruaryAmount();
    setMarchAmount();
    setAprilAmount();
    setMayAmount();
    setJuneAmount();
    setJulyAmount();
    setAugustAmount();
    setSeptemberAmount();
    setOctoberAmount();
    setNovemberAmount();
    setDecemberAmount();

//resetMonths
     console.log(props.params.data.periods);
    Object.entries(props.params.data).forEach(([key, value]) => {
      if (
        key != "category" &&
        key != "category_id" &&
        key != "id" &&
        key != "poste" &&
        key != "type" &&
        key != "type_id" &&
        key != "periods" &&
        key != "disabled" &&
                key != "vat"
      ) {
       console.log(key + ":" + value);
        let month = key.substr(4, 2);
        let periodId=null;
        if (props.params.data.periods !==undefined && props.params.data.periods!== null){

          if (props.params.data.periods[key] !== undefined )
            periodId = props.params.data.periods[key];
        }
      console.log(key + ' '+ month);
      monthstoPeriod[getMonthName( month, value)]= [periodId, month];
      }
    });
    if (props.params.data.periods === undefined) {
      console.log(props.monthsColums);
      props.monthsColums.forEach(( value) => {
        let monthNumber = value.substr(4, 2);
        console.log(value + "" + monthNumber);
        console.log(props.params.data[value]);
        monthstoPeriod[getMonthName(monthNumber, "")] = [
          props.params.data[value],
          monthNumber,
        ];
      });
      console.log(monthstoPeriod);
    }
    
    setMonthPeriods(monthstoPeriod);
    //if (props.data)

    // eval("set"+actual_month+'Amount')(value.amount);
  }

function getMonthName(month, value) {
  let monthName = "";
  if (month == "01") {
    monthName = "january";
    // monthstoPeriod["january"] = [periodId, month];
    //  monthstoPeriod.push( "january" : [periodId , month]);
    eval("setJanuaryAmount")(value);
  } else if (month == "02") {
    monthName = "february";
    // monthstoPeriod["february"] = [periodId, month];
    //monthstoPeriod.push({ "february":[periodId, month]});
    eval("setFebruaryAmount")(value);
  } else if (month == "03") {
    monthName = "march";
    //monthstoPeriod["march"] = [periodId, month];
    //monthstoPeriod.push({"march":[ periodId, month]});
    eval("setMarchAmount")(value);
  } else if (month == "04") {
    monthName = "april";
    //monthstoPeriod["april"] = [periodId, month];
    //monthstoPeriod.push({"april":[ periodId, month]});
    eval("setAprilAmount")(value);
  } else if (month == "05") {
    monthName = "may";
    // monthstoPeriod["may"] = [periodId, month];
    //monthstoPeriod.push({"may":[ periodId, month]});
    eval("setMayAmount")(value);
  } else if (month == "06") {
    monthName = "june";
    // monthstoPeriod["june"] = [periodId, month];
    // monthstoPeriod.push({"june":[ periodId, month]});
    eval("setJuneAmount")(value);
  } else if (month == "07") {
    //monthstoPeriod["july"] = [periodId, month];
    monthName = "july";
    //  monthstoPeriod.push({"july":[periodId, month]});
    eval("setJulyAmount")(value);
  } else if (month == "08") {
    //monthstoPeriod["august"] = [periodId, month];
    monthName = "august";
    //monthstoPeriod.push({"august":[ periodId, month]});
    eval("setAugustAmount")(value);
  } else if (month == "09") {
    // monthstoPeriod["september"] = [periodId, month];
    monthName = "september";
    // monthstoPeriod.push({"september":[ periodId, month]});
    eval("setSeptemberAmount")(value);
  } else if (month == "10") {
    // monthstoPeriod["october"] = [periodId, month];
    monthName = "october";
    //monthstoPeriod.push({"october":[ periodId, month]});
    eval("setOctoberAmount")(value);
  } else if (month == "11") {
    // monthstoPeriod["november"] = [periodId, month];
    monthName = "november";
    // monthstoPeriod.push({"november":[ periodId, month]});
    eval("setNovemberAmount")(value);
  } else if (month == "12") {
    // monthstoPeriod["december"] = [periodId, month];
    monthName = "december";
    // monthstoPeriod.push({ "december": [periodId, month] });
    eval("setDecemberAmount")(value);
  }
  return monthName;
}

  let monthsToShow = [
    "january",
    "february",
    "march",
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'];

 // let monthsToShow = [
 //,{4:'april'},{5:'may'},{6:'june'},{7:'july'},{8:'august'},{9:'september'},{10:'october'},{11:'november'},{12:'december'}];

  const sendEntry = () => {
    // console.log("boum");

    //let report="api/reports/"+actualReport;
    let report = props.reportId;
    //console.log("report " +actualReport);
    let type = "api/forecast_types/2";
    let type_id = "2";
    let newAmount = fixedAmount;

    let newStartDate;
    let entryPeriods = [];
    if (newAmount < 0) {
      type = "api/forecast_types/1";
      type_id = "1";
    //  newAmount = -fixedAmount;
    }

  if (name == undefined || name == '') {
    alert("Saisissez un nom");
    return '';
  }

  if(category== undefined || category==""){

    alert("Sélectionner une catégorie");
    return '';
  }

  if(vatSelected==null || vatSelected==undefined){
    console.log(vatSelected);
    alert("Sélectionner un taux de TVA");
    return '';

  }

  if( (Number(newAmount) === NaN || Number(newAmount) === '0' ) && !showVariableAmount){
    console.log(Number(newAmount));
   alert('Saisissez un montant');
    return '';
  }


    let newPeriodicity = periodicity;
    //console.log('showVariableAmount' + showVariableAmount);
    let monthId=null;
    if (showVariableAmount) {
      console.log("showVariableAmount " + showVariableAmount);
     // console.log(props.params.data.periods);
      // props.params.data.periods.forEach((key) => {
      //    console.log(key.substr(4, 2) + " : ");
      // });
      monthsToShow.forEach(( value) => {
        monthId = null;
        newAmount = eval(value + "Amount");
           // newPeriodicity = "/api/intervals/10";
          //console.log(value + ":" + props.params.data.periods[value]);
        //  console.log(value + ':'+ monthPeriods[value][0]+':'+ monthPeriods[value][1]);
        if (monthPeriods[value]!== undefined) monthId = monthPeriods[value][0];
        if(newAmount!=""){
          if (monthId != null) {
            entryPeriods.push({
              id:monthPeriods[value][0],
              "@id": "/api/entry_periods/" + monthPeriods[value][0],
              amount: newAmount.toString(),
              recurringInterval: "/api/intervals/10",
              period: monthPeriods[value][1],
            });
          } else  if(newAmount!='0'){
            entryPeriods.push({
              amount: newAmount.toString(),
              recurringInterval: "/api/intervals/10",
              period: monthPeriods[value][1],
            });
          }

        }                

        // console.log(value + " : " + eval(value + "Amount"));
      });
      //tricks for api
      newPeriodicity = "/api/intervals/10";
      //console.log(monthPeriods );

      // monthPeriods.forEach((key) => {
      //   /* key = 200.49:2022-12-01 => amount, last date, after previous month */
      //   console.log(key);
      //   //key = key.split(":");
      //   newAmount = eval(key[0] + "Amount");
      //   if (newAmount < 0) {
      //     type = "api/forecast_types/1";
      //     type_id = 1;
      //     newAmount = -newAmount;
      //   } else {
      //     type = "api/forecast_types/2";
      //     type_id = 2;
      //   }
      //   newStartDate = key[1];
      //   //let periodicity=
      //   //console.log("value "+key[0] +' '+eval(key[0]+'Amount') +',date :' +key[0],);
      //   entryPeriods.push({
      //     id: "/api/entry_periods/" + key[1],
      //     amount: newAmount.toString(),
      //     recurringInterval: newPeriodicity,
      //     period: key[2],
      //   });
      //   //cashFlowApi.postEntry(report,type,newAmount.toString(),name,category,newPeriodicity,recurring,newStartDate,finish);
      // });
      // console.log();
    }
    else {
        console.log('empty entry');
      entryPeriods=[];
    }


    console.log(newAmount);
    if (id!="" )
      props.putEntry(
        id,
        newAmount.toString(),
        props.categoryType,
        category,
        entryPeriods,
        name,
        periodicity,
        periodicityLabel,
        props.params,
        recurring,
        startDateFormated,
        finishDateFormated,
        true,
        categoryId,
        categoryName,
        true,
        vatSelected,
        vatSelectedLabel,
        disabled
      );
else {
  //let mappedEntry =["label:"+props.mappedName,"bankAccount:/api/accounts_infos/"+props.bankAccount];
  // let mappedEntry ="{label:"+name+,"bankAccount:/api/accounts_infos/"+bankAccount+"}";


      props.putEntry(
        id,
        newAmount.toString(),
        props.categoryType,
        category,
        entryPeriods,
        name,
        newPeriodicity,
        props.params,
        recurring,
        start,
        finish,
        true,
        categoryId,
        categoryName,
        true,
        vatSelected,
        vatSelectedLabel,
        false
      );

      // cashFlowApi.postEntry(
      //   props.reportId,
      //  "api/forecast_types/"+ props.categoryType,
      //   newAmount.toString(),
      //   name,
      //   category,
      //   newPeriodicity,
      //   recurring,
      //   start,
      //   finish,
      //   entryPeriods,
      //   props.mappedName,
      //   props.bankAccount
      // );

}

    onClose();
  };

  //const monthsToShow=12;

  const [category, setCategory] = useState(1);
   const [categoryId, setCategoryId] = useState(1);
    const [categoryName, setCategoryName] = useState(1);
  //const [selectedCategory,setSelectedCategory]=useState(1);
  //let selectedCategory;
  // if (typeof props.params.data !== "undefined")
  //   selectedCategory = {
  //     //value: "/api/forecast_categories/" + props.params.data.category_id,
  //     value:  props.params.data.category_id,
  //     label: props.params.data.category,
  //   };
  
const openVatModal =()=>{
    //modalIsOpen = true;
    
    props.openVatModal();
};

  const onCategoryChange = (value) => {
  //  console.log(selectedCategory);
    // resetMonths();
    if (value != null) {
      //console.log(Object.entries(value)[0][1]);
      setCategory(value.value);
      let id = value.value.split("/");
      //console.log(value.value);
      //console.log(value.label);
      //console.log(value.value + " " + id[3] + " " + value.label);
      setCategoryId(id[3]);
      setCategoryName(value.label);
    }
  };

  const onCategoryAdd = (value) => {
    console.log(value.value+'Boom ' + value.label);
    props.addNewCategory(value.value,value.label,props.categoryType);
   // setCategory(value.value);
   // props.categoryNameChange(value.label);

    setCategoryId(value.value);
    setCategoryName(value.label);
};


  let [revenueCategories, setRevenueCategories] = useState([]);
  let [expenseCategories, setExpenseCategories] = useState([]);

  let [title, setTitle] = useState([]);

  let [periodicities, setPeriodicities] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    console.log("lol perdio");

    if (periodicities.length == 0) {
      Object.entries(intervals["hydra:member"]).forEach(([key, value]) => {
        periodicities.push({ value: value["@id"], label: value.label });
      });
    } else redirect();
  }, [periodicities]);

let [vats, setVats] = useState([]);
  
  // useEffect(() => {
  //   let isSubscribed = true;
  //   console.log("lol vat");

  //   if (vats.length == 0) {
  //     Object.entries(vatsFromFile["hydra:member"]).forEach(([key, value]) => {

  //       let checked= localStorage.getItem(value["@id"]);
  //       if(checked===null)checked=false;
  //       if(checked)vats.push({ value: value["@id"], label: value.rate });
  //     });
  //   } 
  // }, [vats]);

  const [recurring, setRecurring] = useState(props.recurring);

  const recurringChange = (value) => {
    //console.log("bam" + value.checked);
    setRecurring(value.checked);
  };

  const [disabled, setDisabled] = useState("");

  const disabledChange = () => {
console.log("qqsd"+props.disabled);
    //setDisabled(value.checked);
    setDisabled(true);
        console.log("name : " +name)
    console.log("disabled : " +disabled)
   // sendEntry();
  };
useEffect(() => {
  if(disabled)sendEntry();
}, [disabled]);

  const nameChange = (e) => {
    //console.log("name" + e.target.value);
    setName(e.target.value);
  };

  const [showVariableAmount, setShowVariableAmount] = useState(true);
  const [showFixedAmount, setShowFixedAmount] = useState(false);

  const variableAmountChange = (value) => {
       console.log("variableAmountChange avant" + showVariableAmount +' :'+value.checked);
    setShowVariableAmount(value.checked);
    setShowFixedAmount(!value.checked);
  
    console.log("variableAmountChange après" + showVariableAmount);
//    console.log(props.params);
  //  console.log(props.params.data);
    //console.log(props.params.data.poste);
  };

const [advancedMode, setAdvancedMode] = useState(true);
const [showAdvancedMode, setShowAdvancedMode] = useState(false);
 
  const advancedModeChange = (value) => {
    setAdvancedMode(value.checked);
    setShowAdvancedMode(value.checked);
    // setShowVariableAmount(value.checked);
    // setShowFixedAmount(!value.checked);
  };


  const [periodicity, setPeriodicity] = useState();
  const [periodicityLabel, setPeriodicityLabel] = useState();

  const onPeriodicityChange = (value) => {
    if (value != null) {
      console.log(Object.entries(value)[0][1]);
      setPeriodicity(Object.entries(value)[0][1]);
      setPeriodicityLabel(Object.entries(value)[0][0]);
    }
  };

  const [vatSelectedLabel, setVatSelectedLabel] = useState();
  const [vatSelected, setVatSelected] = useState();
  
  const onVatChange = (value) => {
    if (value != null) {
      console.log(Object.entries(value)[0][1]);
      setVatSelected(Object.entries(value)[0][1]);
      setVatSelectedLabel(Object.entries(value)[0][0]);
    }
  };

  const [startDateFormated, setStartDateFormated] = useState(null);
  //const [startDate, setStartDate] = useState(null);
  const startOnChange = (value) => {
    // console.log("date"+value);
    // setName(value);
    setStartDateFormated(value);
  };

  const [finishDateFormated, setFinishDateFormated] = useState(null);
  //const [finishDate, setFinishDate] = useState(null);
  const finishOnChange = (value) => {
    // console.log("date"+value);
    // setName(value);
    setFinishDateFormated(value);
  };
  let options = null;

  if (props.categoryType == "1") {
    options = props.expenseCategories;
    //setOptions();
  } else {
    options = props.revenueCategories;
    //  setOptions();
  }



  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} isCentered>
      <ModalOverlay />
      <ModalContent  maxW="600px">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
   
          <Stack direction="row" spacing="24px">
            <FormControl>
              <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                Libellé
              </FormLabel>
              <Input
                fontSize="xs"
                placeholder=""
                defaultValue={props.params.data.poste}
                onChange={(e) => nameChange(e)}
              />
              <input name="disabled" value="{disabled}" type="hidden" />
            </FormControl>
          </Stack>
          <Stack direction="row" spacing="24px">
            <FormControl>
              <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                Catégorie
              </FormLabel>
              <Category
                options={options}
                selectedCategory={props.selectedCategory}
                onCategoryChange={onCategoryChange}
                categoryType={props.categoryType}
                company={props.company}
                onCategoryAdd={onCategoryAdd}
              ></Category>
            </FormControl>
      
              <FormControl>
                <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                  TVA
                </FormLabel>
                <Vat
                  options={vats}
                  vatSelected={props.vatSelected}
                  onChange={onVatChange}
                ></Vat>
              </FormControl>
          </Stack>
          <Stack direction="row" spacing="24px">
              <FormControl>
                <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                  A partir du
                </FormLabel>

                <StartDate
                  label="Début"
                  onChange={startOnChange}
                  value={startDateFormated}
                //  date={startDateFormated}
                 isOpen={props.isOpen}
                ></StartDate>
              </FormControl>

            <FormControl //variant="floating"
            >
              <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                Jusqu'au
              </FormLabel>
              <StartDate
                label="Fin"
                onChange={finishOnChange}
               value={finishDateFormated}
               // date={finishDatForme}
                isOpen={props.isOpen}
              >
              </StartDate>
            </FormControl>
          </Stack>
            <Stack direction="row" spacing="24px">
              <FormControl>
                <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                  Montant
                </FormLabel>
                <Input
                  fontSize="xs"
                  placeholder=""
                  defaultValue={props.fixedAmount}
                  onChange={fixedAmountChange}
                  disabled={showVariableAmount}
                />
              </FormControl>


              <FormControl>
                <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                  Périodicité
                </FormLabel>
                <Periodicity
                  options={periodicities}
                  periodicitySelected={periodicitySelected}
                  onChange={onPeriodicityChange}
                  disabled={showVariableAmount}
                ></Periodicity>
              </FormControl>

            </Stack>


      <Stack
            direction="row"
            spacing="20px"
            w="100%"
   
          >

            <Switch
              colorScheme="cyan"
              defaultChecked={showVariableAmount}
              onChange={(e) => variableAmountChange(e.target)}
            />
          <FormControl alignItems="center">
            <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
              Montant mois par mois
            </FormLabel>

          </FormControl>
</Stack>
          <Stack
            direction="row"
            spacing="20px"
            w="100%"
            style={{ display: showFixedAmount ? "" : "none" }}
          >
</Stack>

          <>
            <Stack
              direction="column"
              spacing="10px"
              w="100%"
              style={{ display: showVariableAmount ? "" : "none" }}
            >
              <Stack direction={{ sm: "column", md: "row" }} spacing="10px">

                <MonthValue
                  defaultValue={januaryAmount}
                  label={"Janvier"}
                  onChange={januaryOnChange}
                />

                <MonthValue
                  defaultValue={februaryAmount}
                  label={"Février"}
                  onChange={februaryOnChange}
                />

                  <MonthValue
                  defaultValue={marchAmount}
                  label={"Mars"}
                  onChange={marchOnChange}
                />
   
              </Stack>

              <Stack direction={{ sm: "column", md: "row" }} spacing="10px">
       
                <MonthValue
                  defaultValue={aprilAmount}
                  label={"Avril"}
                  onChange={aprilOnChange}
                />

   
              <MonthValue
                  defaultValue={mayAmount}
                  label={"Mai"}
                  onChange={mayOnChange}
                />

                <MonthValue
                  defaultValue={juneAmount}
                  label={"Juin"}
                  onChange={juneOnChange}
                />

              </Stack>

              <Stack direction={{ sm: "column", md: "row" }} spacing="10px">

                  <MonthValue
                  defaultValue={julyAmount}
                  label={"Juillet"}
                  onChange={julyOnChange}
                />

                
                <MonthValue
                  defaultValue={augustAmount}
                  label={"Aout"}
                  onChange={augustOnChange}
                />

                <MonthValue
                  defaultValue={septemberAmount}
                  label={"Septembre"}
                  onChange={septemberOnChange}
                />
              </Stack>

              <Stack direction={{ sm: "column", md: "row" }} spacing="10px">

              <MonthValue
                  defaultValue={octoberAmount}
                  label={"Octobre"}
                  onChange={octoberOnChange}
                />
                
                <MonthValue
                  defaultValue={novemberAmount}
                  label={"Novembre"}
                  onChange={novemberOnChange}
                />
                <MonthValue
                  defaultValue={decemberAmount}
                  label={"Décembre"}
                  onChange={decemberOnChange}
                />
              </Stack>


            </Stack>
      </>
         


          <Stack direction={{ sm: "column", md: "row" }} spacing="10px">
 {/* 
         <FormControl alignItems="center">
            <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
              Gestion avancée des montants
            </FormLabel>

            <Switch
              colorScheme="cyan"
              defaultChecked={showAdvancedMode}
              onChange={(e) => advancedModeChange(e.target)}
            />
          </FormControl>
*/}
                  <FormControl>
                  
 {/* 
                <Stack direction="row" spacing="4px">
                  <FormLabel  fontSize="xs"  fontWeight="bold" mb="10px" minW="70px">
                  Désactiver
                </FormLabel>
                <Switch
                  colorScheme="cyan"
                  defaultChecked={props.disabled}
                  onChange={(e) => disabledChange(e.target)}
                />
                </Stack>
*/}

                {/* <Stack direction="row" spacing="4px">
                //     <FormLabel  fontSize="xs"  fontWeight="bold" mb="10px" minW="70px">
         
                //   Récurrent
                // </FormLabel>
                // <Switch
                //   colorScheme="cyan"
                //   defaultChecked={props.recurring}
                //   onChange={(e) => switchChange(e.target)}
                // />
                // </Stack>
                */}

              </FormControl>
            </Stack>

        </ModalBody>
     
        <ModalFooter>
          {/* <Button onClick={onClose}>Close</Button> */}
     
             <Button
              type="submit"
              bg="red.300"
              fontSize="sm"
              color="white"
              fontWeight="bold"
          
              _hover={{
                bg: "red.200",
              }}
              _active={{
                bg: "red.400",
              }}
              onClick={disabledChange}

              style={{ marginRight: '60px' }}
            >
              Supprimer
            </Button>

              <Button
              type="submit"
              bg="teal.300"
              fontSize="sm"
              color="white"
              fontWeight="bold"
          
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
                         onClick={sendEntry}
            >
              Enregistrer
            </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>


  );
}

export default EntryModal;